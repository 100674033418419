import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { PrincipalContainer } from '../../components/containers'
import './index.css'
import HowToReg from '@mui/icons-material/HowToReg'

export const ValidadorCredenciales = () => {

    const [loading, setLoading] = useState(false)
    const [userFound, setUserFound] = useState({})
    const {qrId} = useParams()

    useEffect(() => {
        console.log("QR ID:", qrId)
        handleInfo()
    }, [])


    const handleInfo = async () => {
        setLoading(true)
        try {
            const response = await axios.get(`https://sii.tsj.mx:3002/validacion/${qrId}`);
      
            console.log(response.data);
            setLoading(false)
            setUserFound(response.data)
        } catch (error) {
            console.error('Error:', error);
        }
      };


  if(loading){return <p>Cargando...</p>}
    

  return (

    <PrincipalContainer>
        <div className='screen-validadorCredencialesContainer'>

            <div className='screen-personPhotoContainer'>
                <img src={userFound.imagen}/>
            </div>

            {userFound.tipo==="Alumno" && userFound.status === 'Vigente' ? 

                <div className='screen-personDataContainer'>
                    <p id='thin'>{userFound.tipo}</p>
                    <p>{userFound.nombre}</p>
                    <p id='thin'>{userFound.campus}</p>
                    <p id='thin'>{userFound.carrera}</p>
                    <p id='thin'>{userFound.calendario}</p>

                    <div className='screen-personVerified'>
                        <HowToReg className='screen-iconPersonVerified'/>
                        <p id='studentStatus'>Alumno Vigente</p>
                    </div>
                </div>
            
        
            :

                <div className='screen-personDataContainer'>
                    <p>{userFound.nombre}</p>
                    <p id='thin'>{userFound.tipo}</p>
                    <p id='thin'>{userFound.campus}</p>
                    <p id='thin'>{userFound.area}</p>
                    <p id='thin'>{userFound.noEmpleado}</p>
                    <p id='thin'>{userFound.puesto}</p>

                    <div className='screen-personVerified'>
                        <HowToReg className='screen-iconPersonVerified'/>
                    </div>
                </div>



            }







        </div>
    </PrincipalContainer>
//     <div>
//         {loading ? <a>Cargando...</a> : null}

//         {userFound && 
//         <>
//             <PrincipalContainer>

//             <div className='credencialContainer'>

// <div>
// <div className="credencialPhoto">
//     <img className='credencialPhoto' src={userFound.imagen}/>
// </div>
// </div>

// <div className="credencialSquares">
// <img  src='https://tecmm.edu.mx/web-tsj-designs/colorsSquares.png'/>
// </div>


// <div className='credencialInfo'>
// <h2 style={{textAlign:'center'}}>{userFound.tipo}</h2>
// <h1>{userFound.nombre}</h1>
// <h2><a>Matricula:</a> {userFound.matricula}</h2>
// <h2><a>Carrera:</a> {userFound.carrera}</h2>
// <h2><a>Semestre:</a> {userFound.semestre}</h2>
// <h2><a>Unidad Academica:</a> {userFound.campus}</h2>
// <h2><a>Periodo: </a>{userFound.calendario}</h2>
// <h2><a>Status: </a>{userFound.status}</h2>

// <br/>
// <br/>
// <br/>
// <br/>
// <br/>

// </div>


// </div>

//             </PrincipalContainer>

        
        
        
        
//         </>
        
        
//         }


//     </div>
  )
}
