import HomeIcon from '@mui/icons-material/Home';
import Youtube from '@mui/icons-material/YouTube'



import './index.css'

export const NavBar = () => {
  return (
    <div className='component-navBarContainer'>
        <div className='component-navBarItem'>
            <img src='https://app.tecmm.mx/resources/images/logos/logoTsj-01.png'/>
        </div>

        {/* <div className='component-navBarItem'>
            <HomeIcon className='homeIcon' fontSize='large'/>
        </div> */}

        {/* <div className='component-navBarItem'>
            <p>Sobre el TSJ</p>
        </div>

        <div className='component-navBarItem'>
            <p>Servicios Escolares</p>
        </div>

        <div className='component-navBarItem'>
            <p>Oferta Educativa</p>
        </div>

        <div className='component-navBarItem'>
            <p>Unidades Académicas</p>
        </div>

        <div className='component-navBarItem'>
            <p>Contacto</p>
        </div>

        <div className='component-navBarItem'>
            <p>Normatividad</p>
        </div>

        <div className='component-navBarItem'>
            <p>Edcore</p>
        </div> */}

        {/* <div className='component-navBarItemRowIcons'>
            <Youtube className='youtubeIcon' fontSize='large'/>
            <Youtube className='youtubeIcon' fontSize='large'/>
            <Youtube className='youtubeIcon' fontSize='large'/>
        </div> */}

    </div>
  );
}

export default NavBar;