import React, { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import { useGetPersonsEdcore } from './hooks/useGetPersonsEdcore';
import { PersonsTableEdcore } from './components/personsTableEdcore/personsTableEdcore';
import { BrowserRouter } from 'react-router-dom';
import { MainRouter } from './navigation/mainRouter';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {

  const { personsEdcore, loadingEdcore, errorEdcore } = useGetPersonsEdcore();

  useEffect(() => {
    //console.log(personsEdcore)
  }, [loadingEdcore]) 

  return (
    <div className="App">
      <BrowserRouter>
        <MainRouter/>
      </BrowserRouter>
    </div>
  );
  

  // return (
  //   <div className="App">
  //     <header className="App-header">
  //       <p>
  //         Alumnos activos UNIDAD ACADEMICA ZAPOPAN
  //       </p>
  //       <PersonsTable/>
  //     </header>
  //   </div>
  // );
}

export default App;
