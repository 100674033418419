import apiClientEdcore from "./axiosInstanceEdcore"
import apiClientMobileApp from "./axiosInstanceMobileApp"

export const loginRequest = async (email:string, password:string)=>{
    try{
        const response = await apiClientMobileApp.post('/loginTemporal', {email, password});
        return response.data
    }catch(error){
        console.error('error en la solicitud de loginTemporal', error);
        throw error;
    }
}



export const getPersonsEdcore = async()=>{
    try{
        const response = await apiClientEdcore.get('/persons')
        return response.data
    }catch(error){
        console.log('error en la solicitud', error)
        throw error
    }
}

export const getPersonsMobileApp = async()=>{
    try{
        const response = await apiClientMobileApp.get('/getPersons')
        return response.data
    }catch(error){
        console.log('error en la solicitud', error)
        throw error
    }
}

export const getPersonsAdminsDg = async()=>{
    try{
        const response = await apiClientMobileApp.get('/getAdminsDg')
        return response.data
    }catch(error){
        console.log('error en la solicitud', error)
        throw error
    }
}

export const updateExportedStatus = async (ids:number[]) => {
    try {
        const response = await apiClientMobileApp.put('/updateExported', {ids} );
        return response;
    } catch (error) {
        console.log('Error en la actualización de los registros:', error);
        throw error;
    }
};