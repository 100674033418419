import axios from "axios"

export const apiClientMobileApp = axios.create({
    baseURL:'https://app.tecmm.mx/api/',
    timeout:10000,
    headers:{
        'Content-Type':'application/json',
        'Accept':'application/json'
    },
})

export default apiClientMobileApp