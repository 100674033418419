import React from 'react'
import {  Routes, Route, useNavigate, redirect } from "react-router-dom";
import { EdcoreDatabase } from '../screens/edcoreDatabase/edcoreDatabase';
import { MobileAppDatabase } from '../screens/mobileAppDatabase/mobileAppDatabase';
import { ValidadorCredenciales } from '../screens/validarCredencialScreen';
import { GenerarCredencial } from '../screens/generarCredencial';
import { AllPersons } from '../screens/allpersons';
import { Login } from '../screens/login';
import { PrivateRoute } from './privateRoute';



export const MainRouter = () => {
  return (
    <Routes>

      {/* Rutas Publicas */}
        <Route path="/login" element={<Login/>}/>
        <Route path="/validarCredencial/:qrId" element={<ValidadorCredenciales/>}/>
        <Route path="/validadorCredencialAdmins/:qrId" element={<ValidadorCredenciales/>}/>
      {/* Rutas Privadas */}

        <Route
          path="/" 
          element={
            <PrivateRoute>
              <AllPersons/>
            </PrivateRoute>
          }
        />

        {/* <Route path="/edcoreDatabase" element={<EdcoreDatabase/>}/>
        <Route path="/generarCredencial" element={<GenerarCredencial/>}/> */}

    </Routes>
  )
}
