import React, { useState } from 'react'
import './index.css'
import { PrincipalContainer } from '../../components/containers'
import { FormControl, IconButton, Input, InputAdornment, InputLabel, OutlinedInput } from '@mui/material'
import { AccountCircle, Email, Visibility, VisibilityOff } from '@mui/icons-material'
import { useLogin } from '../../hooks/useLogin'

export const Login = () => {

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const {login, data, loading, error} = useLogin(email, password)


    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
      };
    
      const handleMouseUpPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
      };

    const handleLogin = () =>{
        login()
    }


  return (
    <div className='screen-loginPrincipalContainer'>

        <div className='screen-loginFormContainer'>
            <div className='form'>
                <img src='https://app.tecmm.mx/resources/images/logos/logoTsj-02.png'/>

                <h3>Inicia Sesión</h3>

                <div className='screen-loginInputsContainer'>
                    <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password">Correo electronico</InputLabel>
                        <OutlinedInput
                            onChange={(e)=>setEmail(e.target.value)}
                            value={email}
                            id="outlined-adornment-password"
                            type='text'
                            endAdornment={
                            <InputAdornment position="end">
                                <IconButton

                                edge="end"
                                >
                                    <Email/>
                                </IconButton>
                            </InputAdornment>
                            }
                            label="email"
                        />
                    </FormControl>

                    <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password">Contraseña</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password"
                            onChange={(e)=>setPassword(e.target.value)}
                            value={password}
                            type={showPassword ? 'text' : 'password'}
                            endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                aria-label={
                                    showPassword ? 'hide the password' : 'display the password'
                                }
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                onMouseUp={handleMouseUpPassword}
                                edge="end"
                                >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                            }
                            label="Password"
                        />
                    </FormControl>
                </div>

                <button className="login-button" onClick={()=>handleLogin()}>
                    <p>Iniciar sesión</p>
                </button>

            </div>
        </div>

    </div>
  )
}
