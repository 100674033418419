import axios from 'axios'

const apiClientEdcore = axios.create({
    baseURL:'http://localhost:4000',
    timeout:10000,
    headers:{
        'Content-Type':'application/json',
        'Accept':'application/json'
    },
})

export default apiClientEdcore;