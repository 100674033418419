import React, { useEffect, useState } from 'react'
import { useGetPersonsEdcore } from '../../hooks/useGetPersonsEdcore';
import './index.css'
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import usePersonsToExport from '../../hooks/usePersonsToExport';
import Table from 'react-bootstrap/Table';
import Papa from 'papaparse';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Visibility from '@mui/icons-material/Visibility'
import Block from '@mui/icons-material/Block'

import { CheckBox } from '../../components/checkBox';
import { useGetPersonsMobileApp } from '../../hooks/useGetPersonsMobileApp';
import { updateExportedStatus } from '../../helpers/api';
import { BlockOutlined, CarCrash, CarRental, Logout, NoCrash } from '@mui/icons-material';
import { useLogin } from '../../hooks/useLogin';





export const AllPersons = ({persons, loading, error}:any) => {

  const [carrerasZapopan, setCarrerasZapopan] = useState([
    {index:1, value:"ARQUITECTURA"},
    {index:2, value:"MAESTRÍA EN SISTEMAS COMPUTACIONALES"},
    {index:3, value:"INGENIERÍA ELECTRÓNICA"},
    {index:4, value:"INGENIERÍA CIVIL"},
    {index:5, value:"LICENCIATURA EN GASTRONOMÍA"},
    {index:6, value:"INGENIERÍA EN SISTEMAS COMPUTACIONALES"},
    {index:7, value:"INGENIERÍA ELECTROMECÁNICA"},
    {index:8, value:"INGENIERÍA INDUSTRIAL"},
    {index:9, value:"INGENIERÍA EN GESTIÓN EMPRESARIAL"},
  ])
  const [carreraSelected, setCarreraSelected] = useState("")

  const [organizations, setOrganizations] = useState([
    {index:1, value:"tsj_zapopan_alumnos"},
    {index:2, value:"tsj_dg_administrativos"},
    {index:3, value:"tsj_zapopan_administrativos"},
    {index:4, value:"tsj_zapopan_docentes"},
    {index:5, value:"tsj_enLinea_administrativos"}

  ])

  const {logout} = useLogin('email', 'password')


  const { personsMobileApp, loadingMobileApp, errorMobileApp } = useGetPersonsMobileApp();
  const [organizationSelected, setOrganizationSelected] = useState("")

  const [personsOriginal, setPersonsOriginal] = useState(personsMobileApp)
  

  
  
  const [filteredPersons, setFilteredPersons] = useState(personsMobileApp)
  const [nameFilter, setNameFilter] = useState("")

  const { personsToExport, togglePersonToExport } = usePersonsToExport();

  const [personToModal, setPersonToModal] = useState<any>(null)

  //const [sortedPeople, setSortedPeople] = useState(filteredPersons);
  const [sortOrder, setSortOrder] = useState("asc"); // Controla el orden ascendente/descendente


  const [show, setShow] = useState(false);
  const [showModalPersonsToExport, setShowModalPersonsToExport] = useState(false)

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleExportClick = (persona: any) => {

    if(personsToExport.length>=250) alert('recuerda que solo hay que exportar maximo 250 personas')

    // Alterna el estado de exportado de la persona
    const nuevasPersonas = filteredPersons.map((p:any) =>
      p.personID === persona.personID ? { ...p, exportado: !p.exportado } : p
    );

    const nuevasPersonasOriginal = personsOriginal.map((p:any) =>
      p.personID === persona.personID ? { ...p, exportado: !p.exportado } : p
    );

    setFilteredPersons(nuevasPersonas);
    setPersonsOriginal(nuevasPersonasOriginal);

    // Agrega o elimina la persona del arreglo personsToExport
    togglePersonToExport(persona);
  };

  useEffect(() => {
    let filtered = personsOriginal;

    if (carreraSelected) {
      filtered = filtered.filter((person: any) =>
        person.carrera === carreraSelected
      );
    }

    if (organizationSelected) {
      filtered = filtered.filter((person: any) =>
        person.organization === organizationSelected
      );
    }

    if (nameFilter) {
      filtered = filtered.filter((person: any) =>
        `${person.personName}`.toLowerCase().includes(nameFilter.toLowerCase())
      );
    }

    setFilteredPersons(filtered);
  }, [carreraSelected, nameFilter, organizationSelected, personsOriginal]);

  useEffect(() => {
    
    setPersonsOriginal(personsMobileApp);
    setFilteredPersons(personsMobileApp);
  }, [personsMobileApp]);

  const generateCSVAndZip = async () => {
    console.log('llega a la funcion');
  
    // Definir los encabezados del CSV
    const headers = ["*Person ID", "*Organization", "*Person Name", "*Gender", "Contact", "Email", "Effective Time", "Expiry Time", "Card No.", "Room No.", "FloorNo."];
  
    // Crear el contenido del CSV a partir de la plantilla
    let csvContent = headers.join(",") + "\n"; // Agrega encabezados separados por comas
  
    // Recorrer el array de personas y agregar solo los datos requeridos
    personsToExport.forEach(person => {
      const row = [
        person.personID,
        person.organization, 
        person.personName,
        person.gender,
        '',
        '', 
        `'${person.effectiveTime}`,
        `'${person.expiryTime}`,
        person.cardNo,
        person.roomNo,
        person.floorNo
      ];
      csvContent += row.join(",") + "\n"; // Combina los datos en una fila separada por comas
    });
  
    // Crear un Blob con el contenido CSV
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  
    // Usar FileSaver.js para guardar el archivo
    saveAs(blob, 'personas_exportadas.csv');
  
    // Crear el archivo ZIP
    const zip = new JSZip();
  
    // Descargar cada imagen y agregarla al archivo ZIP
    const imageDownloads = personsToExport.map(async (person) => {
      try {
        // Descargar la imagen como un array buffer
        const imageResponse = await axios.get(person.urlImage, { responseType: 'arraybuffer' });
  
        // Agregar la imagen al archivo ZIP
        zip.file(
          `${person.personID}_${person.personName}.jpg`,
          imageResponse.data,
          { binary: true } // Asegura que los datos se manejen como binarios
        );
      } catch (error) {
        console.error(`Error descargando la imagen de la persona ${person.personID}:`, error);
      }
    });
  
    // Esperar a que todas las imágenes se descarguen
    await Promise.all(imageDownloads);
  
    // Generar el archivo ZIP y descargarlo
    zip.generateAsync({ type: 'blob' }).then((content) => {
      saveAs(content, 'persons_photos.zip');
    });
  };


  const exportDataAsCSVWithTemplate = () => {



    // Definir los encabezados del CSV
    const headers = ["*Person ID", "*Organization", "*Person Name", "*Gender", "Contact", "Email", "Effective Time", "Expiry Time", "Card No.", "Room No.", "FloorNo."];
  
    // Crear el contenido del CSV a partir de la plantilla
    let csvContent = headers.join(",") + "\n"; // Agrega encabezados separados por comas
  
    // Recorrer el array de personas y agregar solo los datos requeridos
    personsToExport.forEach(person => {
      const row = [
        person.personID,
        person.organization, 
        person.personName,
        person.gender,
        person.contact,
        person.email, 
        `'${person.effectiveTime}`,
        `'${person.expiryTime}`,
        person.cardNo,
        person.roomNo,
        person.floorNo
      ];
      csvContent += row.join(",") + "\n"; // Combina los datos en una fila separada por comas
    });
  
    // Crear un Blob con el contenido CSV
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  
    // Usar FileSaver.js para guardar el archivo
    saveAs(blob, 'personas_exportadas.csv');
  };


  const exportPersonsData = async () =>{
    const idsArray = personsToExport.map(person => person.personID)


    try{
        const result = await updateExportedStatus(idsArray)
        if(result.data.status === 'ok'){
            generateCSVAndZip()
        }

    }catch(error){
        console.log('Error al actualizar los registros: ', error)
    }
  }


  const ModalPersonsToExport = () => {
    return(
        <Modal size='xl' centered show={showModalPersonsToExport} onHide={()=>setShowModalPersonsToExport(false)}>
            <Modal.Header closeButton>
            <Modal.Title>Personas a exportar</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className='screen-tableToExport'>
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th className='theadClassExported'>personID</th>
                        <th className='theadClassExported'>Seleccionar</th>
                        <th className='theadClassExported'>personName</th>
                        <th className='theadClassExported'>organization</th>
                        <th className='theadClassExported'>rol</th>
                        <th className='theadClassExported'>fotografia</th>
                    </tr>
                    </thead>
                    <tbody>
                    {personsToExport.map((person:any, index:any) => (
                        <tr key={person.personID}>
                            <td style={{textAlign:'center'}}>{person.personID}</td>
                            <td style={{textAlign:'center'}}>
                                <button style={{backgroundColor:'#ff4d63', color:'white', fontSize:'1.2rem'}} className='addDeleteButton' onClick={() => handleExportClick(person)}>
                                    {'X'}
                                </button>
                            </td>
                            <td>{person.personName}</td>
                            <td>{person.organization}</td>
                            <td>{person.rol}</td>
                            <td>{person.urlImage!='' ? <a href={person.urlImage} target='_blank'>ver foto</a> : 'No foto'} </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
                </div>

            </Modal.Body>

            
            <Modal.Footer>
            <Button variant="secondary" onClick={()=>setShowModalPersonsToExport(false)}>
                Close
            </Button>

            {personsToExport.length <= 0 ?
                null
            :
                <Button variant="primary" onClick={()=>exportPersonsData()}>
                    Descargar archivos
                </Button>
            }



            </Modal.Footer>
        </Modal>
    )
  }

  // Función para ordenar por cualquier campo
  const sortTable = (key:string) => {
    const sortedArray = [...filteredPersons].sort((a, b) => {
      if (key === "personName") {
        // Ordena alfabéticamente
        return sortOrder === "asc"
          ? a[key].localeCompare(b[key])
          : b[key].localeCompare(a[key]);
      } else if (key === "vehicle") {
        // Ordena para que primero aparezcan los que tienen "Vehiculo" en 1
        return sortOrder === "asc"
          ? b[key] - a[key]
          : a[key] - b[key];
      } else if (key === "carrera") {
        // Ordena alfabéticamente
        return sortOrder === "asc"
          ? a[key].localeCompare(b[key])
          : b[key].localeCompare(a[key]);
      }
      return 0;
    });

    setFilteredPersons(sortedArray);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc"); // Alterna entre ascendente y descendente
  };



    if (filteredPersons === null) return <div>Cargando...</div>;
    return (
        <div>

            <ModalPersonsToExport/>

            {personToModal === null ? null
            
                :

                <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{personToModal.personName}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <a href={personToModal.urlImage} target='_blank' download="FOTO.jpg">

                    <img style={{width:'200px', height:'200px'}} src={personToModal.urlImage}/>

                    </a>
                </Modal.Body>


                <Modal.Body style={{fontWeight:'bold', fontSize:'5rem'}}>{personToModal.cardNo}</Modal.Body>

                
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                    Close
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                    Save Changes
                    </Button>
                </Modal.Footer>
                </Modal>
            }


          <div style={{display:'flex'}}>

            <div className='screen-tablePersons'>

              <div className='screen-filtrosContainer'>
                <Form.Select onChange={(e:any)=>setCarreraSelected(e.target.value)} className='formSelect' aria-label="Default select example">
                  <option value={''}>Selecciona una carrera</option>

                  {carrerasZapopan.map((item)=>{
                    return <option key={item.index} value={item.value}>{item.value}</option>

                  })}

                </Form.Select>

                <Form.Select onChange={(e:any)=>setOrganizationSelected(e.target.value)} className='formSelect' aria-label="Default select example">
                  <option value={''}>Selecciona una organizacion</option>

                  {organizations.map((item)=>{
                    return <option key={item.index} value={item.value}>{item.value}</option>

                  })}

                </Form.Select>

                <input value={nameFilter} onChange={(e)=>{setNameFilter(e.target.value)}} className='inputName' placeholder='Nombre'/>

                <div className='screen-personsToExportCounter'>
                    <h3>Personas a exportar: {personsToExport.length}</h3>
                </div>

                {/* <button onClick={()=>generateCSVAndZip()} className='buttonDescarga'>Descargar</button> */}
                <button onClick={()=>setShowModalPersonsToExport(!showModalPersonsToExport)} className='buttonDescarga'>Exportar</button>

                <button onClick={logout} className='buttonDescarga'>
                  Cerrar sesión &nbsp;
                  <Logout/>
                </button>


              </div>

              <div className='tableContainer'>
                
                <table className='tableMobileApp'>
                  <thead>
                    <tr>
                      <th className='theadClass'>id</th>
                      <th className='theadClass'>
                        <CheckBox/>
                      </th>
                      <th className='theadClass'>
                        <Visibility/>
                      </th>
                      <th className='theadClass'>noControl</th>
                      <th className='theadClass'>exportado</th>
                      <th onClick={() => sortTable("carrera")} className='theadClassButton'>carrera</th>
                      <th onClick={() => sortTable("personName")} className='theadClassButton'>personName</th>
                      <th className='theadClass'>organization</th>
                      <th className='theadClass'>rol</th>
                      <th onClick={() => sortTable("vehicle")} className='theadClassButton'>vehicle</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredPersons.map((person:any, index:any) => (
                      <tr 
                        // onClick={() => [setPersonToModal(person), setShow(true)]}
                        style={{backgroundColor:index%2 === 0 ? 'rgba(48, 143, 255, 0.4)':'transparent'}}
                        key={person.personID}
                        className='tableTr'
                      >
                          <td style={{textAlign:'center'}}>{person.personID}</td>
                          <td style={{textAlign:'center'}}>
                            {person.urlImage != '' ? 
                            <button style={{backgroundColor: person.exportado ?'#ff4d63':'#54c98f', color:'white', fontSize:'1.2rem'}} className='addDeleteButton' onClick={() => handleExportClick(person)}>
                                {person.exportado ? '-' : '+'}
                            </button>

                            :
                            <button 
                                style={{backgroundColor: '#d8d8d8', color:'white', fontSize:'1.2rem'}} 
                                className='addDeleteButton' 
                                onClick={() => null}
                            >
                                <Block/>
                            </button>

                            
                            }

                          </td>
                          <td onClick={() => [setPersonToModal(person), setShow(true)]}>
                            <Visibility/>
                          </td>
                          <td>{person.noControl}</td>
                          <td>{person.lastExport != null ? new Date(person.lastExport).toLocaleString() : null}</td>
                          
                          <td>{person.carrera}</td>
                          <td>{person.personName}</td>
                          {/* <td>{person.gender}</td> */}
                          <td>{person.organization}</td>
                          {/* <td>{person.effectiveTime}</td>
                          <td>{person.expiryTime}</td> */}
                          <td>{person.rol}</td>
                          <td>{person.vehicle === 1 ? <NoCrash/>:<BlockOutlined/>}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
    )
}
