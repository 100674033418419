import React from 'react'
import './index.css'

export const CheckBox = () => {
  return (
    <label className="custom-checkbox">
        <input type="checkbox" />
        <span className="checkmark"></span>
    </label>
  )
}
