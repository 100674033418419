import { useState } from "react";
import { loginRequest } from "../helpers/api";
import { useNavigate } from "react-router-dom";



export const useLogin = (email:string, password:string) => {
    const [data, setData] = useState<any>(null)
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<any>(null)
    const navigate = useNavigate(); // Usa useNavigate para navegar después del login


    const logout = async () => {
        localStorage.removeItem('loggedUser'); // Borra el "token"
        navigate('/login'); // Redirige al usuario al login
    }


    const login = async () => {
        setLoading(true);

        try{

            const result = await loginRequest(email, password);

            if(result){
                //const token = result.token
                await localStorage.setItem('loggedUser', result.user.email);
                //setUserEmail(result.user.email);  // Actualizar contexto
                navigate('/'); // Redirige después de iniciar sesión
            } else {
                    throw new Error('Respuesta del API no contiene un token válido');
                }
        }catch (err){
            setError(err)
            console.log(err)
        }finally{
            setLoading(false)
        }
    }

    return { login, logout, data, loading, error };
}