import React from 'react'
import { Navbar } from 'react-bootstrap'
import NavBar from '../navBar'
import './index.css'
export const PrincipalContainer = ({children}:any) => {
  return (
    <div className='component-principalContainer'>
        <NavBar/>     

        <div className='component-childrenContainer'>
            {children}
        </div>   
        
    </div>
  )
}
