import { useState } from 'react';

// Definición del tipo de persona
type Persona = {
    cardNo:string,
    carrera:string,
    contact:string,
    curp:string,
    effectiveTime:string,
    email:string,
    expiryTime:string,
    exportado:number,
    floorNo:string,
    gender:number,
    idFromCoreDB:string,
    noControl:string,
    organization:string,
    personID:number,
    personName:string,
    rol:string
    roomNo:string
    urlImage:string
    vehicle:number
};

// Hook personalizado
const usePersonsToExport = () => {
  const [personsToExport, setPersonsToExport] = useState<Persona[]>([]);

  // Función para agregar o quitar una persona del arreglo personsToExport
  const togglePersonToExport = (persona: Persona) => {
    setPersonsToExport(prevState => {
      // Verifica si la persona ya está en el arreglo
      const exists = prevState.some(p => p.personID === persona.personID);

      if (exists) {
        // Si ya está, la elimina
        return prevState.filter(p => p.personID !== persona.personID);
      } 
      
      // if(prevState.length>=2){
      //   alert('no se puede')
      //   return prevState
      // }
      else {
        // Si no está, la agrega
        return [...prevState, persona];
      }
    });
  };

  return { personsToExport, togglePersonToExport };
};

export default usePersonsToExport;