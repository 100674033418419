import React, { useEffect, useState } from 'react'
import { getPersonsEdcore } from '../helpers/api';

export const useGetPersonsEdcore = () => {

    const [personsEdcore, setPersonsEdcore] = useState<any>(null)
    const [loadingEdcore, setLoadingEdcore] = useState<boolean>(true);
    const [errorEdcore, setErrorEdcore] = useState<Error | null>(null);

    useEffect(() => {
        const fetchPersons = async () => {
          try {
            setLoadingEdcore(true);
            const data = await getPersonsEdcore();
            //console.log('EDCORE DATA: ', data)
            setPersonsEdcore(data);
          } catch (err) {
            setErrorEdcore(err as Error);
          } finally {
            setLoadingEdcore(false);
          }
        };
    
        fetchPersons();
      }, []);


    return { personsEdcore, loadingEdcore, errorEdcore };
}
