import React from 'react'
import { useGetPersonsEdcore } from '../../hooks/useGetPersonsEdcore';
import './index.css'
import axios from 'axios';

export const PersonsTableEdcore = ({persons, loading, error}:any) => {
    const { personsEdcore, loadingEdcore, errorEdcore } = useGetPersonsEdcore();


    const migrateData = async () => {
       for (const person of personsEdcore) {
         const payload = {
           organization: 'tsj_zapopan_alumnos',
           personName:`${person.firstName} ${person.secondName} ${person.name}`,
           gender:person.gender,
           contact: '',
           email: '',
           effectiveTime: '2024-07-01 00:00:00',
           expiryTime: '2034-07-01 00:00:00',
           cardNo: '',
           roomNo: '',
           floorNo: '',
           rol: 'alumno',
           vehicle: 0,
           noControl:person.code,
           idFromCoreDB:person.id,
           curp:person.curp,
           carrera:person.programName,
           urlImage:''
         };

         //console.log(payload)
  
         try {
         //   // Usando axios para enviar los datos
           const response = await axios.post('http://201.116.192.158:3000/persons', payload);
           console.log(`Migración exitosa para la persona con ID: ${person.id}`, response.data);
         } catch (error) {
           console.error(`Error al migrar persona con ID: ${person.id}`, error);
         }
       }
       alert('Migración completada.');
     };




    if (loading) return <div>Cargando...</div>;
    if (error) return <div>Error al cargar personas: {error.message}</div>;
    return (
        <div>
          
          <button onClick={migrateData}>Migrar datos</button>

          <table>
            <thead>
              <tr>
                <th>id</th>
                <th>idFromCoreDB</th>
                <th>noControl</th>
                <th>organization</th>
                <th>personName</th>
                <th>gender</th>
                <th>contact</th>
                <th>email</th>
                <th>effectiveTime</th>
                <th>expiryTime</th>
                <th>cardNo</th>
                <th>roomNo</th>
                <th>floorNo</th>
                <th>rol</th>
                <th>vehicle</th>
              </tr>
            </thead>
            <tbody>
              {persons.map((person:any, index:any) => (
                <tr key={person.id}>
                    <td>{index}</td>
                    <td>{person.id}</td>
                    <td>{person.code}</td>
                    <td>tsj_zapopan_alumnos</td>
                    <td>{person.firstName + ' ' + person.secondName + ' ' + person.name}</td>
                    <td>{person.gender}</td>
                    <td>NA</td>
                    <td>NA</td>
                    <td>1/07/2024 0:00:00</td>
                    <td>1/07/2034 0:00:00</td>
                    <td>NA</td>
                    <td>NA</td>
                    <td>NA</td>
                    <td>alumno</td>
                    <td>0</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
    )
}
