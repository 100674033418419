import React, { useEffect, useState } from 'react'
import { getPersonsMobileApp } from '../helpers/api';

export const useGetPersonsMobileApp = () => {

    const [personsMobileApp, setPersonsMobileApp] = useState<any>(null)
    const [loadingMobileApp, setLoadingMobileApp] = useState<boolean>(true);
    const [errorMobileApp, setErrorMobileApp] = useState<Error | null>(null);

    useEffect(() => {
        const fetchPersons = async () => {
          try {
            setLoadingMobileApp(true);
            const data = await getPersonsMobileApp();
            setPersonsMobileApp(data);
          } catch (err) {
            setErrorMobileApp(err as Error);
          } finally {
            setLoadingMobileApp(false);
          }
        };
    
        fetchPersons();
      }, []);


    return { personsMobileApp, loadingMobileApp, errorMobileApp };
}
